import createDataContext from './createDataContext'

// Reducer
const ModalContext = (state, action) => {
  switch (action.type) {
    case 'SET_CASHIER_MODAL_SHOW':
      return { ...state, cashierModalShow: action.payload }
    case 'SET_CASHIER_MODAL_PROPS':
      return { ...state, cashierModalProps: action.payload }
    case 'SET_MESSAGE_MODAL_SHOW':
      return { ...state, messageModalShow: action.payload }
    case 'SET_MESSAGE_MODAL_PROPS':
      return { ...state, messageModalProps: action.payload }
    default:
      return state
  }
}

// Actions
const setCashierModalShow = (dispatch) => (value) => {
  dispatch({ type: 'SET_CASHIER_MODAL_SHOW', payload: value })
}

const setCashierModalProps = (dispatch) => (props) => {
  dispatch({ type: 'SET_CASHIER_MODAL_PROPS', payload: props })
}

const setMessageModalShow = (dispatch) => (value) => {
  dispatch({ type: 'SET_MESSAGE_MODAL_SHOW', payload: value })
}

const setMessageModalProps = (dispatch) => (props) => {
  dispatch({ type: 'SET_MESSAGE_MODAL_PROPS', payload: props })
}

export const { Context, Provider } = createDataContext(
  ModalContext,
  {
    setCashierModalShow,
    setCashierModalProps,
    setMessageModalShow,
    setMessageModalProps,
  },
  // Initial state
  {
    cashierModalShow: false,
    cashierModalProps: null,
    messageModalShow: false,
    messageModalProps: null,
  }
)

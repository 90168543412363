import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'

import Loader from '../../../loader/Loader'
import { Context as CashierContext } from '../../../../../context/CashierContext'
import './transactionHistory.css'

const TransactionHistory = () => {
  const [transactionList, setTransactionList] = useState([])

  const {
    state: { loading, user, transactionHistory },
    fetchTransactionHistory,
  } = useContext(CashierContext)

  // useEffect(() => {
  //   fetchTransactionHistory()
  // }, [])

  useEffect(() => {
    if (user && transactionHistory.length !== 0) {
      let list = transactionHistory.filter((tra) => {
        return tra.clientUsername === user.username
      })
      setTransactionList(list)
    }
  }, [user, transactionHistory])

  const renderTransactionHistoryList = () => {
    if (transactionList.length < 1) return null
    return transactionList.map((tra) => {
      let traValueFormatted = (tra.value / 100).toFixed(2)
      let traClientOldBalanceFormatted = (tra.clientOldBalance / 100).toFixed(2)
      let traClientNewBalanceFormatted = (tra.clientNewBalance / 100).toFixed(2)
      return (
        <div className="transactionHistoryListContainer" key={tra._id}>
          <div className="transactionHistoryListCell">
            <div className="transactionHistoryListCellLabel">time</div>
            <div className="transactionHistoryListText">
              {moment(tra.created).format('dddd, MMMM Do YYYY, h:mm:ss a')}
            </div>
          </div>
          <div className="transactionHistoryListCell">
            <div className="transactionHistoryListCellLabel">type</div>
            <div className="transactionHistoryListText">
              {tra.type === 'addCredit' ? 'add credit' : 'cash out'}
            </div>
          </div>
          <div className="transactionHistoryListCell">
            <div className="transactionHistoryListCellLabel">value</div>
            <div className="transactionHistoryListText">
              {!tra.value ? null : traValueFormatted}
            </div>
          </div>
          <div className="transactionHistoryListCell">
            <div className="transactionHistoryListCellLabel">before</div>
            <div className="transactionHistoryListText">
              {traClientOldBalanceFormatted}
            </div>
          </div>
          <div className="transactionHistoryListCell">
            <div className="transactionHistoryListCellLabel">after</div>
            <div className="transactionHistoryListText">
              {traClientNewBalanceFormatted}
            </div>
          </div>
        </div>
      )
    })
  }

  const renderContent = () => {
    if (loading) return <Loader />
    return (
      <>
        {!user ? null : (
          <div className="transactionHistoryListHeader">
            Cash history for {user.username.slice(6)}
          </div>
        )}
        <div className="transactionHistoryListBed">
          {renderTransactionHistoryList()}
        </div>
      </>
    )
  }

  return renderContent()
}

export default TransactionHistory

import React, { useContext, useEffect, useState } from 'react'
import { RiComputerLine } from 'react-icons/ri'

import RemoveUserFromCashOutArray from './helpers/RemoveUserFromCashOutArray'
import { Context as CashierContext } from '../../context/CashierContext'
import { Context as ModalContext } from '../../context/ModalContext'
import { Context as SocketContext } from '../../context/SocketContext'

const ClientCell = ({ client }) => {
  const [cashOutUsername, setCashOutUsername] = useState('')
  const [cashOutButtonClass, setCashOutButtonClass] = useState(
    'cashierCashoutButton'
  )
  const [jackpotClearButtonShow, setJackpotClearButtonShow] = useState(false)

  const {
    state: {
      userCashOutClassToClear,
      fetchClientInQuestionBalanceLoading,
      clientInQuestion,
      clientInQuestionBalance,
    },
    fetchUser,
    setUserCashOtClassToClear,
    setClientInQuestion,
    fetchClientInQuestionBalance,
    clearClientInQuestionBalance,
    resetPromoWheelEntryAdded,
  } = useContext(CashierContext)

  const {
    setCashierModalProps,
    setCashierModalShow,
    setMessageModalShow,
    setMessageModalProps,
  } = useContext(ModalContext)

  const {
    state: { clientCashoutArray, jackpotWinner, jackpotCleared },
    setSocketDataFromCashier,
    setJackpotRelease,
    setJackpotGiven,
    setJackpotWinner,
  } = useContext(SocketContext)

  useEffect(() => {
    resetPromoWheelEntryAdded(false)
  }, [])

  useEffect(() => {
    if (clientCashoutArray.length > 0) {
      let userName = clientCashoutArray.filter((item) => {
        return item.clientUsername === client.username
      })[0]
      if (userName) {
        setCashOutUsername(userName)
      }
    }
  }, [client, clientCashoutArray])

  useEffect(() => {
    if (userCashOutClassToClear === client.username) {
      setCashOutButtonClass('cashierCashoutButton')
      setUserCashOtClassToClear(null)
    }
  }, [userCashOutClassToClear])

  useEffect(() => {
    if (cashOutUsername) {
      if (cashOutUsername.clientUsername === client.username) {
        setCashOutButtonClass('cashierCashoutButtonFlicker')
      }
    }
  }, [cashOutUsername, client])

  useEffect(() => {
    if (client.username === jackpotWinner) {
      setTimeout(() => {
        setJackpotClearButtonShow(true)
      }, 7000)
    }
  }, [client, jackpotWinner])

  useEffect(() => {
    if (jackpotCleared) {
      setSocketDataFromCashier({
        subject: 'reFetchJackpotData',
        reFetchJackpotData: true,
      })
    }
  }, [jackpotCleared])

  useEffect(() => {
    if (clientInQuestion) {
      if (clientInQuestion.clientUsername === client.username) {
        fetchClientInQuestionBalance({ clientId: client._id })
      }
    }
  }, [clientInQuestion])

  useEffect(() => {
    if (clientInQuestionBalance) {
      setTimeout(() => {
        setClientInQuestion(null)
        clearClientInQuestionBalance(null)
      }, 5000)
    }
  }, [clientInQuestionBalance])

  const renderCheckBalanceButton = () => {
    if (!clientInQuestion) {
      return (
        <div
          className="cashierCheckClientBalanceButton"
          onClick={() => {
            setClientInQuestion({
              clientUsername: client.username,
            })
          }}
        >
          check balance
        </div>
      )
    }
    if (clientInQuestion.clientUsername === client.username) {
      if (fetchClientInQuestionBalanceLoading) {
        return <div className="cashierCheckClientBalanceButton">Loading...</div>
      }
      return (
        <div className="cashierCheckClientBalanceButton">
          {(clientInQuestionBalance / 100).toFixed(2)}
        </div>
      )
    } else {
      return (
        <div
          className="cashierCheckClientBalanceButton"
          onClick={() => {
            setClientInQuestion({
              clientUsername: client.username,
            })
          }}
        >
          check balance
        </div>
      )
    }
  }

  const jackpotReset = () => {
    setSocketDataFromCashier({
      subject: 'resetJackpotState',
      clientUsername: client.username,
      resetJackpotState: true,
    })
    setTimeout(() => {
      setSocketDataFromCashier({
        subject: 'reload',
        reload: true,
        clientUsername: client.username,
      })
    }, 5000)
  }

  const renderJackpotWon = () => {
    return (
      <>
        <div className="cashierClearJackpotButton">
          <div className="cashierClearJackpotButtontext">Jackpot!</div>
        </div>
        {!jackpotClearButtonShow ? null : (
          <div
            className="cashierClearClientHistoryButton"
            onClick={() => {
              jackpotReset()
              setJackpotRelease(false)
              setJackpotGiven(false)
              setJackpotWinner(null)
            }}
          >
            clear jackpot
          </div>
        )}
      </>
    )
  }

  const renderCashOutButton = () => {
    if (cashOutButtonClass === 'cashierCashoutButtonFlicker') {
      return (
        <div
          className="cashierCashoutButtonFlicker"
          onClick={() => {
            setCashierModalProps({ type: 'cashOut' })
            fetchUser(client._id)
            setCashierModalShow(true)
          }}
        >
          Cash out
        </div>
      )
    } else {
      return (
        <div
          className="cashierCashoutButton"
          onClick={() => {
            setCashierModalProps({ type: 'cashOut' })
            fetchUser(client._id)
            setCashierModalShow(true)
            setSocketDataFromCashier({
              subject: 'cashOut',
              cashOut: true,
              clientUsername: client.username,
              lock: true,
            })
          }}
        >
          Cash out
        </div>
      )
    }
  }

  const renderButtons = () => {
    return (
      <>
        <div
          className="cashierAddCreditButton"
          onClick={() => {
            setSocketDataFromCashier({
              subject: 'addCredit',
              addCredit: true,
              clientUsername: client.username,
              lock: true,
            })
            setCashierModalProps({ type: 'addCredit' })
            fetchUser(client._id)
            setCashierModalShow(true)
          }}
        >
          + Add
        </div>
        {renderCashOutButton()}
        <div
          className="cashierTransactionHistoryButton"
          onClick={() => {
            setCashierModalProps({ type: 'transactionHistory' })
            fetchUser(client._id)
            setCashierModalShow(true)
          }}
        >
          cash history
        </div>
        {renderCheckBalanceButton()}
        <div
          className="cashierClearClientHistoryButton"
          onClick={() => {
            setSocketDataFromCashier({
              subject: 'reload',
              reload: true,
              clientUsername: client.username,
            })
          }}
        >
          refresh
        </div>
        <div
          className="cashierSignOutClientButton"
          onClick={() => {
            setMessageModalShow(true)
            setMessageModalProps({
              bit: 'signOutOneUser',
              clientUsername: client.username,
            })
          }}
        >
          sign out
        </div>
      </>
    )
  }

  const buttonsSelecter = () => {
    if (client.username === jackpotWinner) {
      return renderJackpotWon()
    } else {
      return renderButtons()
    }
  }

  const renderContent = () => {
    return (
      <div className="cashierUsersListItem">
        <div className="cashierUsersListItemLabel">
          {client.username.slice(6)}
        </div>
        <div className="cashierUsersListItemIconBed">
          <RiComputerLine className="cashierUsersListItemIcon" />
        </div>
        {buttonsSelecter()}
      </div>
    )
  }

  return (
    <>
      <RemoveUserFromCashOutArray />
      {renderContent()}
    </>
  )
}

export default ClientCell

import React, { useEffect, useState } from 'react'
import Navbar from '../../common/navbar/Navbar'
import io from 'socket.io-client'

// const socket = io.connect('http://localhost:5000')

const Status = () => {
  const [socketChanel, setSocketChanel] = useState('')
  // const [lock, setLock] = useState(false)

  const [message, setMessage] = useState()
  const [messageReceived, setMessageReceived] = useState('')

  // useEffect(() => {
  //   socket.on('receive_message', (data) => {
  //     setMessageReceived(data.message)
  //   })
  // }, [socket])

  const joinRoom = () => {
    // if (socketChanel !== '') {
    //   socket.emit('join_chanel', socketChanel)
    // }
  }

  const sendMessage = () => {
    // socket.emit('send_message', { message, socketChanel })
  }

  const data = {
    user: 'user001',
    lock: false,
  }

  const renderContent = () => {
    return (
      <div className="bed">
        <Navbar />
        <div>Status</div>
        <div>
          <input
            placeholder="Room Number..."
            // onChange={(event) => {
            //   setSocketChanel(event.target.value)
            // }}
          />
          <button onClick={joinRoom}> Join Room</button>
          <input
            placeholder="Message..."
            onChange={(event) => {
              setMessage(event.target.value)
            }}
          />
          <button onClick={() => setMessage(data)}>set data</button>
          <button onClick={sendMessage}> Send Message</button>
          {/* <button onClick={() => setLock(!lock)}>set lock</button>
          <button onClick={sendLockState}>send lock state</button> */}
          <h1> Message:</h1>
          {/* {messageReceived} */}
        </div>
      </div>
    )
  }

  return renderContent()
}

export default Status

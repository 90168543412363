import ngrokApi from '../api/ngrokApi'
import createDataContext from './createDataContext'

// Reducer
const TestPlayContext = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'ADD_ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'CLEAR_ERROR':
      return { ...state, error: action.payload }
    case 'ADD_SUCCESS':
      return { ...state, success: action.payload, loading: false }
    case 'CLEAR_SUCCESS':
      return { ...state, success: action.payload }
    case 'SET_TRIGGER_AUTO_CASH_OUT':
      return { ...state, triggerAutoCashOut: action.payload }
    case 'SET_TEST_CLIENT':
      return { ...state, testClient: action.payload }
    case 'SET_TEST_CLIENT_BALANCE':
      return { ...state, testClientBalance: action.payload }
    case 'RECORD_TRANSACTION_HISTORY':
      return { ...state, transactionHistory: action.payload, loading: false }
    default:
      return state
  }
}

// Actions
const setTriggerAutoCashOut = (dispatch) => (value) => {
  dispatch({ type: 'SET_TRIGGER_AUTO_CASH_OUT', payload: value })
}

const setTestClient = (dispatch) => (data) => {
  dispatch({ type: 'SET_TEST_CLIENT', payload: data })
}

const setTestClientBalance = (dispatch) => (data) => {
  dispatch({ type: 'SET_TEST_CLIENT_BALANCE', payload: data })
}

const clearError = (dispatch) => () => {
  dispatch({ type: 'CLEAR_ERROR', payload: null })
}

const clearSuccess = (dispatch) => () => {
  dispatch({ type: 'CLEAR_SUCCESS', payload: null })
}

const updateUserCredits = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/test-play/update-credit', data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
    }
    return
  } catch (error) {
    dispatch({ type: 'STOP_LOADING' })
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const recordTransactionHistory = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/test-play/record-transaction', data)
    dispatch({ type: 'RECORD_TRANSACTION_HISTORY', payload: response.data })
    return
  } catch (error) {
    dispatch({ type: 'STOP_LOADING' })
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

export const { Context, Provider } = createDataContext(
  TestPlayContext,
  {
    setTriggerAutoCashOut,
    setTestClient,
    setTestClientBalance,
    clearError,
    clearSuccess,
    updateUserCredits,
    recordTransactionHistory,
  },
  // Initial state
  {
    loading: null,
    error: null,
    success: null,
    triggerAutoCashOut: false,
    testClient: null,
    testClientBalance: null,
    transactionHistory: null,
  }
)

import React from 'react'
import { Provider as AuthProvider } from '../context/AuthContext'
import { Provider as SocketProvider } from '../context/SocketContext'
import { Provider as UserProvider } from '../context/UserContext'
import { Provider as NavProvider } from '../context/NavContext'
import { Provider as CashierProvider } from '../context/CashierContext'
import { Provider as CashLogsProvider } from '../context/CashLogsContext'
import { Provider as TestPlayProvider } from '../context/TestPlayContext'

import AppScreens from './AppScreens'
import './app.css'

const App = () => {
  return (
    <div className="appBed">
      <AuthProvider>
        <SocketProvider>
          <NavProvider>
            <UserProvider>
              <CashierProvider>
                <CashLogsProvider>
                  <TestPlayProvider>
                    <AppScreens />
                  </TestPlayProvider>
                </CashLogsProvider>
              </CashierProvider>
            </UserProvider>
          </NavProvider>
        </SocketProvider>
      </AuthProvider>
    </div>
  )
}

export default App

import React, { useContext, useEffect } from 'react'

import Socket from './common/socket/Socket'
import Loader from './common/loader/Loader'
import Login from './auth/login/Login'
import Cashier from './screens/cashier/Cashier'
import CashLogs from './screens/cashLogs/CashLogs'
import Status from './screens/status/Status'
import { Provider as ModalProvider } from '../context/ModalContext'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as NavContext } from '../context/NavContext'
import { Context as UserContext } from '../context/UserContext'
import { Context as CashierContext } from '../context/CashierContext'

const AppScreens = () => {
  const {
    state: { loading: loadingAuth },
  } = useContext(AuthContext)

  const {
    state: { visibleComponent },
    setVisibleComponent,
  } = useContext(NavContext)

  const {
    state: { loading: loadingUser, user },
    fetchUser,
  } = useContext(UserContext)

  const { setSite } = useContext(CashierContext)

  useEffect(() => {
    if (localStorage.getItem('token')) {
      fetchUser()
      setVisibleComponent('cashier')
    } else {
      setVisibleComponent('login')
    }
  }, [localStorage.getItem('token')])

  useEffect(() => {
    if (user) {
      const { cashier, siteNumber } = user
      if (!cashier) {
        localStorage.removeItem('token')
        setVisibleComponent('login')
      }
      setSite(siteNumber)
    }
  }, [user])

  const componentSelector = () => {
    switch (visibleComponent) {
      case 'cashier':
        return <Cashier />
        break
      case 'cashLogs':
        return <CashLogs />
        break
      case 'status':
        return <Status />
        break
      default:
        return <Login />
    }
  }

  const renderContent = () => {
    if (loadingAuth || loadingUser) return <Loader />
    return (
      <div className="appBackGroundBed">
        <ul className="appBackGroundShapes">
          <Socket />
          <ModalProvider>{componentSelector()}</ModalProvider>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    )
  }

  return renderContent()
}

export default AppScreens

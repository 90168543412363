import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import { AiFillCloseCircle } from 'react-icons/ai'

import AddCredit from './addCredit/AddCredit'
import CashOut from './cashOut/CashOut'
import TransactionHistory from './transactionHistory/TransactionHistory'

import Loader from '../../loader/Loader'
import { Context as ModalContext } from '../../../../context/ModalContext'
import { Context as CashierContext } from '../../../../context/CashierContext'
import { Context as SocketContext } from '../../../../context/SocketContext'
import './cashierModal.css'

const CashierModal = () => {
  const {
    state: { cashierModalProps, cashierModalShow },
    setCashierModalProps,
    setCashierModalShow,
    setMessageModalShow,
    setMessageModalProps,
  } = useContext(ModalContext)

  const {
    state: { loading, user, addPromoWheelEntryLoading },
    clearError,
    setAmountToAdd,
    setUserCashOtClassToClear,
    setCashOutSomeShow,
  } = useContext(CashierContext)

  const {
    state: { socketTimeoutCheckTrigger },
    setSocketDataFromCashier,
    setTriggerRemoveUserFromCashOutArray,
    setSocketTimeoutCheckTrigger,
    setSocketTimeout,
  } = useContext(SocketContext)

  if (loading || socketTimeoutCheckTrigger) return <Loader />

  if (!cashierModalShow) return null

  const contentSelector = () => {
    if (!cashierModalProps) return null
    if (cashierModalProps.type === 'addCredit') return <AddCredit />
    if (cashierModalProps.type === 'cashOut') return <CashOut />
    if (cashierModalProps.type === 'transactionHistory')
      return <TransactionHistory />
  }

  const renderContent = () => {
    return (
      <div className="cashierModalBed">
        <div className="cashierModalContainer">
          <div className="cashierModalCancelBed">
            {addPromoWheelEntryLoading ? null : (
              <AiFillCloseCircle
                className="cashierModalCancel"
                onClick={() => {
                  setUserCashOtClassToClear(user.username)
                  clearError()
                  setSocketDataFromCashier({
                    subject: 'lock',
                    clientUsername: user.username,
                    lock: false,
                  })
                  setCashOutSomeShow(false)
                  setSocketTimeoutCheckTrigger(false)
                  setAmountToAdd(0)
                  setCashierModalProps(null)
                  setCashierModalShow(false)
                  setTriggerRemoveUserFromCashOutArray(true)
                  setSocketTimeout(false)
                  setMessageModalProps(null)
                  setMessageModalShow(false)
                }}
              />
            )}
          </div>
          {contentSelector()}
        </div>
      </div>
    )
  }

  return ReactDOM.createPortal(
    <div className="ui dimmer active">{renderContent()}</div>,
    document.querySelector('#modal')
  )
}

export default CashierModal

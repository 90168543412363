import { useState, useContext, useEffect } from 'react'

import { Context as TestPlayContext } from '../../../context/TestPlayContext'
import { Context as SocketContext } from '../../../context/SocketContext'
import { Context as CashierContext } from '../../../context/CashierContext'

const TestPlay = () => {
  const [addCreditAmount, setAddCreditAmount] = useState(2000)

  const [triggerRecordCashOutTransaction, setTriggerRecordCashOutTransaction] =
    useState(false)

  const [
    triggerRecordAddCreditTransaction,
    setTriggerRecordAddCreditTransaction,
  ] = useState(false)

  const {
    state: { triggerAutoCashOut, testClient, testClientBalance, success },
    clearSuccess,
    updateUserCredits,
    recordTransactionHistory,
    setTriggerAutoCashOut,
    setTestClient,
  } = useContext(TestPlayContext)

  const {
    state: { site },
  } = useContext(CashierContext)

  useEffect(() => {
    if (triggerAutoCashOut) {
      updateUserCredits({
        type: 'cashOut',
        creditBalance: 0,
        id: testClient._id,
      })
    }
  }, [triggerAutoCashOut, testClient])

  useEffect(() => {
    if (success === 'cashOutSuccessfull') {
      setTriggerRecordCashOutTransaction(true)
      updateUserCredits({
        type: 'addCredit',
        creditBalance: addCreditAmount,
        id: testClient._id,
      })
      clearSuccess()
    }
    if (success === 'addCreditSuccessfull') {
      setTriggerRecordAddCreditTransaction(true)
      clearSuccess()
    }
  }, [success, testClient, testClientBalance])

  useEffect(() => {
    if (triggerRecordAddCreditTransaction) {
      recordTransactionHistory({
        siteNumber: site,
        clientId: testClient._id,
        clientUsername: testClient.username,
        type: 'addCredit',
        value: addCreditAmount,
        clientOldBalance: 0,
        clientNewBalance: addCreditAmount,
      })
      clearSuccess()
      setTriggerRecordAddCreditTransaction(false)
    }
  }, [triggerRecordAddCreditTransaction, testClient, testClientBalance])

  useEffect(() => {
    if (triggerRecordCashOutTransaction) {
      recordTransactionHistory({
        siteNumber: site,
        clientId: testClient._id,
        clientUsername: testClient.username,
        type: 'cashOut',
        value: testClientBalance,
        clientOldBalance: testClientBalance,
        clientNewBalance: 0,
      })
      clearSuccess()
      setTriggerRecordCashOutTransaction(false)
    }
  }, [triggerRecordCashOutTransaction, testClientBalance])

  return null
}

export default TestPlay

import React, { useContext, useEffect, useState } from 'react'
import { RiComputerLine } from 'react-icons/ri'
import { AiFillCheckCircle } from 'react-icons/ai'

import { Context as ModalContext } from '../../../../../context/ModalContext'
import { Context as CashierContext } from '../../../../../context/CashierContext'
import { Context as SocketContext } from '../../../../../context/SocketContext'

import CashOutSome from './cashOutSome/CashOutSome'
import './cashOut.css'

const CashOut = () => {
  const [trigger, setTrigger] = useState(false)

  const { setCashierModalShow } = useContext(ModalContext)

  const {
    state: {
      user,
      site,
      error,
      success,
      cashOutSomeShow,
      transactionHistoryUpdatedValues,
    },
    clearUser,
    clearSuccess,
    setAmountToAdd,
    updateUserCredits,
    recordTransactionHistory,
    setCashOutSomeShow,
    setTransactionHistoryUpdatedValues,
  } = useContext(CashierContext)

  const {
    state: { clientCashoutArray },
    setClientCashoutArray,
    setTriggerRemoveUserFromCashOutArray,
    setSocketDataFromCashier,
  } = useContext(SocketContext)

  useEffect(() => {
    if (success === 'users credit updated') {
      setTrigger(true)
    }
  }, [success])

  useEffect(() => {
    if (trigger) {
      recordTransactionHistory({
        siteNumber: site,
        clientId: user._id,
        clientUsername: user.username,
        type: 'cashOut',
        value:
          transactionHistoryUpdatedValues !== null
            ? transactionHistoryUpdatedValues.transactionValue
            : user.credits,
        clientOldBalance: user.credits,
        clientNewBalance:
          transactionHistoryUpdatedValues !== null
            ? transactionHistoryUpdatedValues.clientNewBalance
            : 0,
      })
      setTransactionHistoryUpdatedValues(null)
      setSocketDataFromCashier({
        subject: 'cashOutDone',
        cashOutDone: true,
        clientUsername: user.username,
        lock: false,
      })
      clearSuccess()
      setAmountToAdd(0)
      setCashierModalShow(false)
      removeUserFromCashoutArray()
      clearUser()
      setTrigger(false)
    }
  }, [trigger, transactionHistoryUpdatedValues])

  const removeUserFromCashoutArray = () => {
    let array = clientCashoutArray
    const { username } = user
    const index = array.findIndex(
      (client) => client.clientUsername === username
    )
    if (index !== -1) {
      array.splice(index, 1)
      setClientCashoutArray(array)
    }
    setTriggerRemoveUserFromCashOutArray(false)
  }

  const renderContent = () => {
    if (!user) return null
    if (error) {
      return (
        <div className="transationErrorBed">
          <div className="transationErrorText">{error}</div>
        </div>
      )
    }
    if (cashOutSomeShow) return <CashOutSome />
    let userCreditsFormatted = (user.credits / 100).toFixed(2)
    return (
      <>
        <div className="typeCashOutTopBed">
          <div className="typeCashOutTopContainer">
            <div className="typeCashOutLabel">{user.username.slice(6)}</div>
            <RiComputerLine className="typeCashOutPcIcon" />
            <div className="typeCashOutBalanceText">
              Balance: {userCreditsFormatted}
            </div>
            {user.credits < 1 ? null : (
              <>
                <div className="typeCashOutText">
                  Are you sure you want to cash out user{' '}
                  {user.username.slice(6)}?
                </div>
                <div className="typeCashOutSubmitConfirmBed">
                  <div
                    className="typeCashOutSubmitConfirm"
                    onClick={() => {
                      updateUserCredits({
                        creditBalance: 0,
                        id: user._id,
                      })
                    }}
                  >
                    All
                  </div>
                  <div
                    className="typeCashOutSubmitConfirm"
                    onClick={() => setCashOutSomeShow(true)}
                  >
                    Some
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  return renderContent()
}

export default CashOut

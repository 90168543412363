import { useContext, useEffect, useState } from 'react'

import socket from '../../../api/socketIo'
import { Context as SocketContext } from '../../../context/SocketContext'
import { Context as CashierContext } from '../../../context/CashierContext'
import { Context as TestPlayContext } from '../../../context/TestPlayContext'
const Socket = () => {
  const [socketSetupDone, setSocketSetupDone] = useState(false)

  const {
    state: {
      socketChanel,
      clientCashoutArray,
      socketDataFromCashier,
      socketDataFromClient,
    },
    setSocketChanel,
    setSocketDataFromCashier,
    setSocketDataFromClient,
    setClientCashoutArray,
    setJackpotRelease,
    setJackpotGiven,
    setJackpotWinner,
    setJackpotCleared,
    setSocketTimeoutCheckTrigger,
  } = useContext(SocketContext)

  const {
    state: { site },
  } = useContext(CashierContext)

  const { setTriggerAutoCashOut, setTestClient, setTestClientBalance } =
    useContext(TestPlayContext)

  useEffect(() => {
    if (site) {
      setSocketChanel(site)
    }
  }, [site])

  useEffect(() => {
    if (socketChanel) {
      socket.emit('join_chanel', socketChanel)
      setSocketSetupDone(true)
    }
  }, [socketChanel])

  useEffect(() => {
    console.log(`socketDataFromCashier`, socketDataFromCashier)
    if (socketDataFromCashier) {
      socket.emit('send_data', { socketDataFromCashier, socketChanel })
      setSocketTimeoutCheckTrigger(true)
      setSocketDataFromCashier(null)
    }
  }, [socketDataFromCashier])

  useEffect(() => {
    if (socketSetupDone) {
      socket.on('receive_data', (data) => {
        if (data) {
          if (data.socketDataFromClient.socketDataReceivedVerified) {
            setSocketTimeoutCheckTrigger(false)
          }
          if (data.socketDataFromClient) {
            setSocketDataFromClient(data.socketDataFromClient)
          }
        }
      })
    }
  }, [socketSetupDone, socket])

  useEffect(() => {
    if (socketDataFromClient) {
      const { subject } = socketDataFromClient
      if (subject === 'cashOut') {
        if (socketDataFromClient.cashOut) {
          setClientCashoutArray([...clientCashoutArray, socketDataFromClient])
          setSocketDataFromClient(null)
        }
      }
      if (subject === 'jackpot') {
        if (socketDataFromClient.jackpotRelease) {
          setJackpotRelease(socketDataFromClient.jackpotRelease)
          setSocketDataFromClient(null)
        }
      }
      if (subject === 'jackpotGiven') {
        if (socketDataFromClient.jackpotGiven) {
          setJackpotGiven(true)
          setJackpotWinner(socketDataFromClient.winningClientUsername)
          setSocketDataFromClient(null)
        }
      }
      if (subject === 'jackpotCleared') {
        if (socketDataFromClient.jackpotCleared) {
          setJackpotCleared(true)
          setSocketDataFromClient(null)
        }
      }
      // TEST PLAY
      if (subject === 'autoCashOutClient') {
        if (socketDataFromClient.autoCashOutClient) {
          setTriggerAutoCashOut(true)
          setTestClient(socketDataFromClient.client)
          setTestClientBalance(socketDataFromClient.gameCredits)
          setSocketDataFromClient(null)
        }
      }
    }
  }, [socketDataFromClient])

  // useEffect(() => {
  //   let message = 'hello from cashier'
  //   setInterval(() => {
  //     console.log(`message should send`)
  //     socket.emit('send_data', { message, socketChanel })
  //   }, 5000)
  // })

  return null
}

export default Socket

import createDataContext from './createDataContext'

// Reducer
const socketReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SOCKET_CHANEL':
      return { ...state, socketChanel: action.payload }
    case 'SET_SOCKET_DATA_FROM_CASHIER':
      return { ...state, socketDataFromCashier: action.payload }
    case 'SET_SOCKET_DATA_FROM_CLIENT':
      return { ...state, socketDataFromClient: action.payload }
    case 'SET_CLIENT_CASHOUT_ARRAY':
      return { ...state, clientCashoutArray: action.payload }
    case 'SET_LOCK':
      return { ...state, lock: action.payload }
    case 'SET_TRIGGER_REMOVE_USER_FROM_CASH_OUT_ARRAY':
      return { ...state, triggerRemoveUserFromCashOutArray: action.payload }
    case 'SET_CLIENT_CASHOUT_ARRAY_COUNT':
      return { ...state, clientCashoutArrayCount: action.payload }
    case 'SET_JACKPOT_RELEASE':
      return { ...state, jackpotRelease: action.payload }
    case 'SET_JACKPOT_GIVEN':
      return { ...state, jackpotGiven: action.payload }
    case 'SET_JACKPOT_WINNER':
      return { ...state, jackpotWinner: action.payload }
    case 'SET_JACKPOT_CLEARED':
      return { ...state, jackpotCleared: action.payload }
    case 'SET_SOCKET_TIMEOUT_CHECK_TRIGGER':
      return { ...state, socketTimeoutCheckTrigger: action.payload }
    case 'SET_SOCKET_TIMEOUT':
      return { ...state, socketTimeout: action.payload }
    default:
      return state
  }
}

// Actions

const setSocketChanel = (dispatch) => (value) => {
  dispatch({ type: 'SET_SOCKET_CHANEL', payload: value })
}

const setSocketDataFromCashier = (dispatch) => (data) => {
  dispatch({ type: 'SET_SOCKET_DATA_FROM_CASHIER', payload: data })
}

const setSocketDataFromClient = (dispatch) => (data) => {
  dispatch({ type: 'SET_SOCKET_DATA_FROM_CLIENT', payload: data })
}

const setClientCashoutArray = (dispatch) => (data) => {
  dispatch({ type: 'SET_CLIENT_CASHOUT_ARRAY', payload: data })
}

const setLock = (dispatch) => (value) => {
  dispatch({ type: 'SET_LOCK', payload: value })
}

const setTriggerRemoveUserFromCashOutArray = (dispatch) => (value) => {
  dispatch({
    type: 'SET_TRIGGER_REMOVE_USER_FROM_CASH_OUT_ARRAY',
    payload: value,
  })
}

const setClientCashoutArrayCount = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_CASHOUT_ARRAY_COUNT', payload: value })
}

const setJackpotRelease = (dispatch) => (value) => {
  dispatch({ type: 'SET_JACKPOT_RELEASE', payload: value })
}

const setJackpotGiven = (dispatch) => (value) => {
  dispatch({ type: 'SET_JACKPOT_GIVEN', payload: value })
}

const setJackpotWinner = (dispatch) => (value) => {
  dispatch({ type: 'SET_JACKPOT_WINNER', payload: value })
}

const setJackpotCleared = (dispatch) => (value) => {
  dispatch({ type: 'SET_JACKPOT_CLEARED', payload: value })
}

const setSocketTimeoutCheckTrigger = (dispatch) => (value) => {
  dispatch({ type: 'SET_SOCKET_TIMEOUT_CHECK_TRIGGER', payload: value })
}

const setSocketTimeout = (dispatch) => (value) => {
  dispatch({ type: 'SET_SOCKET_TIMEOUT', payload: value })
}

export const { Provider, Context } = createDataContext(
  socketReducer,
  {
    setSocketChanel,
    setSocketDataFromCashier,
    setSocketDataFromClient,
    setClientCashoutArray,
    setLock,
    setTriggerRemoveUserFromCashOutArray,
    setClientCashoutArrayCount,
    setJackpotRelease,
    setJackpotGiven,
    setJackpotWinner,
    setJackpotCleared,
    setSocketTimeoutCheckTrigger,
    setSocketTimeout,
  },
  {
    socketChanel: null,
    socketDataFromCashier: null,
    socketDataFromClient: null,
    clientCashoutArray: [],
    lock: false,
    triggerRemoveUserFromCashOutArray: false,
    clientCashoutArrayCount: 0,
    jackpotRelease: false,
    jackpotGiven: false,
    jackpotWinner: null,
    jackpotCleared: false,
    socketTimeoutCheckTrigger: false,
    socketTimeout: false,
  }
)

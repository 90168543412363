import React, { useContext, useEffect, useState } from 'react'

import socket from '../../../api/socketIo'
import Loader from '../loader/Loader'
import MessageModal from '../modals/message/MessageModal'
import { Context as AuthContext } from '../../../context/AuthContext'
import { Context as NavContext } from '../../../context/NavContext'
import { Context as UserContext } from '../../../context/UserContext'
import { Context as CashierContext } from '../../../context/CashierContext'
import { Context as ModalContext } from '../../../context/ModalContext'
import './navbar.css'

const Navbar = () => {
  const [usernameArray, setUsernameArray] = useState([])

  const {
    state: { loading: authLoading, panic, success },
    setPanic,
    signOut,
    clearSuccess,
  } = useContext(AuthContext)

  const { setVisibleComponent } = useContext(NavContext)

  const {
    state: { loading: userLoading },
  } = useContext(UserContext)

  const {
    state: { clients },
    signOutAllUser,
  } = useContext(CashierContext)

  const { setMessageModalShow, setMessageModalProps } = useContext(ModalContext)

  useEffect(() => {
    if (clients.length > 0) {
      let array = clients.map((usr) => {
        return usr.username
      })
      setUsernameArray(array)
    }
  }, [clients])

  useEffect(() => {
    if (success === 'signed out successfully') {
      localStorage.removeItem('token')
      setVisibleComponent('login')
      clearSuccess()
    }
    if (success === 'signed out with panic') {
      setPanic(true)
    }
  }, [success])

  // useEffect(() => {
  //   if (panic && usernameArray.length > 0) {
  //     usernameArray.map((usrn) => {
  //       socket.emit('join_chanel', usrn)
  //       socket.emit('send_panic_state', { panic: true, socketChanel: usrn })
  //     })
  //     setTimeout(() => {
  //       localStorage.removeItem('token')
  //       setVisibleComponent('login')
  //       clearSuccess()
  //       setUsernameArray([])
  //     }, 5000)
  //   }
  // }, [panic, usernameArray])

  const renderContent = () => {
    if (userLoading || authLoading || panic) return <Loader />
    return (
      <div className="navbarBed">
        <div className="navbar">
          <div
            className="navbarButton"
            onClick={() => setVisibleComponent('cashier')}
          >
            cashier
          </div>
          <div
            className="navbarButton"
            onClick={() => setVisibleComponent('cashLogs')}
          >
            cash logs
          </div>
          {/* <div
            className="navbarButton"
            onClick={() => setVisibleComponent('status')}
          >
            status
          </div> */}
          <div
            className="navbarSignoutButton"
            onClick={() => signOut({ panic: false })}
          >
            sign out
          </div>
          <div
            className="navbarSignOutClientsButton"
            onClick={() => {
              setMessageModalShow(true)
              setMessageModalProps({
                bit: 'signOutAllUser',
                clients: usernameArray,
              })
            }}
          >
            sign out clients
          </div>
          <div
            className="navbarPanicButton"
            onClick={() => signOut({ panic: true })}
          >
            panic
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <MessageModal />
      {renderContent()}
    </>
  )
}

export default Navbar

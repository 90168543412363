import ngrokApi from '../api/ngrokApi'
import createDataContext from './createDataContext'

// Reducer
const userReducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'SET_ERROR':
      return { state, error: action.payload }
    case 'FETCH_USER':
      return { ...state, user: action.payload, loading: false }
    case 'SET_USER_CREDIT_BALANCE':
      return { ...state, user: action.payload }
    default:
      return state
  }
}

// Actions
const fetchUser = (dispatch) => async () => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.get('/auth/user/fetch-user')
    if (response.data.error) {
      localStorage.removeItem('token')
      dispatch({ type: 'SET_ERROR', payload: response.data.error })
    }
    dispatch({ type: 'FETCH_USER', payload: response.data })
  } catch (error) {
    dispatch({ type: 'STOP_LOADING' })
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

export const { Provider, Context } = createDataContext(
  userReducer,
  {
    fetchUser,
  },
  { user: null, loading: null, error: null }
)

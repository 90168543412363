import ngrokApi from '../api/ngrokApi'
import createDataContext from './createDataContext'

// Reducer
const userReducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'FETCH_CLIENT_IN_QUESTION_BALANCE_LOADING':
      return { ...state, fetchClientInQuestionBalanceLoading: true }
    case 'ADD_PROMO_WHEEL_ENTRY_LOADING':
      return { ...state, addPromoWheelEntryLoading: true }
    case 'ADD_ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'CLEAR_ERROR':
      return { ...state, error: action.payload }
    case 'ADD_SUCCESS':
      return { ...state, success: action.payload, loading: false }
    case 'CLEAR_SUCCESS':
      return { ...state, success: action.payload }
    case 'SET_SIGHT':
      return { ...state, site: action.payload }
    case 'FETCH_CLIENTS':
      return { ...state, clients: action.payload, loading: false }
    case 'FETCH_USER':
      return { ...state, user: action.payload, loading: false }
    case 'CLEAR_USER':
      return { ...state, user: action.payload }
    case 'SET_AMOUNT_TO_ADD':
      return { ...state, amountToAdd: action.payload }
    case 'RECORD_TRANSACTION_HISTORY':
      return { ...state, transactionHistory: action.payload, loading: false }
    case 'FETCH_TRANSACTION_HISTORY':
      return { ...state, transactionHistory: action.payload, loading: false }
    case 'SET_USER_CASH_OUT_CLASS_TO_CLEAR':
      return { ...state, userCashOutClassToClear: action.payload }
    case 'SET_CLIENT_IN_QUESTION':
      return { ...state, clientInQuestion: action.payload }
    case 'FETCH_CLIENT_IN_QUESTION_BALANCE':
      return {
        ...state,
        clientInQuestionBalance: action.payload,
        fetchClientInQuestionBalanceLoading: false,
      }
    case 'CLEAR_CLIENT_IN_QUESTION_BALANCE':
      return { ...state, clientInQuestionBalance: null }
    case 'SET_CASH_OUT_SOME_SHOW':
      return { ...state, cashOutSomeShow: action.payload }
    case 'SET_TRANSACTION_HISTORY_UPDARED_VALUES':
      return { ...state, transactionHistoryUpdatedValues: action.payload }
    case 'ADD_PROMO_WHEEL_ENTERY':
      return {
        ...state,
        promoWheelEntryAdded: action.payload,
        addPromoWheelEntryLoading: false,
      }
    case 'RESET_PROMO_WHEEL_ENTRY_ADDED':
      return { ...state, promoWheelEntryAdded: action.payload }
    default:
      return state
  }
}

// Actions

const setLoading = (dispatch) => (value) => {
  dispatch({ type: 'SET_LOADING', payload: value })
}

const fetchClients = (dispatch) => async (site) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/cashier/fetch-clients', site)
    dispatch({ type: 'FETCH_CLIENTS', payload: response.data })
    return
  } catch (error) {
    dispatch({ type: 'STOP_LOADING' })
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchUser = (dispatch) => async (id) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/cashier/fetch-user', { id })
    dispatch({ type: 'FETCH_USER', payload: response.data })
    return
  } catch (error) {
    dispatch({ type: 'STOP_LOADING' })
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const updateUserCredits = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/cashier/update-credit', data)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
    }
    return
  } catch (error) {
    dispatch({ type: 'STOP_LOADING' })
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const recordTransactionHistory = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/cashier/record-transaction', data)
    dispatch({ type: 'RECORD_TRANSACTION_HISTORY', payload: response.data })
    return
  } catch (error) {
    dispatch({ type: 'STOP_LOADING' })
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchTransactionHistory = (dispatch) => async () => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.get('/cashier/transaction-history')
    dispatch({ type: 'FETCH_TRANSACTION_HISTORY', payload: response.data })
    return
  } catch (error) {
    dispatch({ type: 'STOP_LOADING' })
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

// Use case: store has power failure, make: { signedIn: false }
const signOutAllUser = (dispatch) => async (clients) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/cashier/sign-out-all', clients)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
    }
    return
  } catch (error) {
    dispatch({ type: 'STOP_LOADING' })
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const signOutOneUser = (dispatch) => async (clients) => {
  dispatch({ type: 'LOADING' })
  try {
    const response = await ngrokApi.post('/cashier/sign-out-one', clients)
    if (response.data.error) {
      dispatch({ type: 'ADD_ERROR', payload: response.data.error })
    }
    if (response.data.success) {
      dispatch({ type: 'ADD_SUCCESS', payload: response.data.success })
    }
    return
  } catch (error) {
    dispatch({ type: 'STOP_LOADING' })
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const clearError = (dispatch) => () => {
  dispatch({ type: 'CLEAR_ERROR', payload: null })
}

const clearSuccess = (dispatch) => () => {
  dispatch({ type: 'CLEAR_SUCCESS', payload: null })
}

const setSite = (dispatch) => async (data) => {
  dispatch({ type: 'SET_SIGHT', payload: data })
}

const clearUser = (dispatch) => () => {
  dispatch({ type: 'CLEAR_USER', payload: null })
}

const setAmountToAdd = (dispatch) => (value) => {
  dispatch({ type: 'SET_AMOUNT_TO_ADD', payload: value })
}

const setJackpotWinnerUsernameDB = (dispatch) => async (data) => {
  try {
    await ngrokApi.post('/payout/jackpot-winner-username', data)
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setUserCashOtClassToClear = (dispatch) => (data) => {
  dispatch({ type: 'SET_USER_CASH_OUT_CLASS_TO_CLEAR', payload: data })
}

const setClientInQuestion = (dispatch) => (data) => {
  dispatch({ type: 'SET_CLIENT_IN_QUESTION', payload: data })
}

const fetchClientInQuestionBalance = (dispatch) => async (data) => {
  dispatch({ type: 'FETCH_CLIENT_IN_QUESTION_BALANCE_LOADING' })
  try {
    const response = await ngrokApi.post('/cashier/fetch-client-balance', data)
    dispatch({
      type: 'FETCH_CLIENT_IN_QUESTION_BALANCE',
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const clearClientInQuestionBalance = (dispatch) => (data) => {
  dispatch({ type: 'CLEAR_CLIENT_IN_QUESTION_BALANCE', payload: data })
}

const setCashOutSomeShow = (dispatch) => (value) => {
  dispatch({ type: 'SET_CASH_OUT_SOME_SHOW', payload: value })
}

const setTransactionHistoryUpdatedValues = (dispatch) => (value) => {
  dispatch({ type: 'SET_TRANSACTION_HISTORY_UPDARED_VALUES', payload: value })
}

const addPromoWheelEntry = (dispatch) => async (data) => {
  dispatch({ type: 'ADD_PROMO_WHEEL_ENTRY_LOADING' })
  try {
    const response = await ngrokApi.post('/cashier/add-promo-wheel-entry', data)
    dispatch({
      type: 'ADD_PROMO_WHEEL_ENTERY',
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const resetPromoWheelEntryAdded = (dispatch) => (value) => {
  dispatch({ type: 'RESET_PROMO_WHEEL_ENTRY_ADDED', payload: value })
}

export const { Provider, Context } = createDataContext(
  userReducer,
  {
    setLoading,
    fetchClients,
    fetchUser,
    clearUser,
    clearError,
    clearSuccess,
    setSite,
    setAmountToAdd,
    updateUserCredits,
    recordTransactionHistory,
    fetchTransactionHistory,
    signOutAllUser,
    signOutOneUser,
    setJackpotWinnerUsernameDB,
    setUserCashOtClassToClear,
    setClientInQuestion,
    fetchClientInQuestionBalance,
    clearClientInQuestionBalance,
    setCashOutSomeShow,
    setTransactionHistoryUpdatedValues,
    addPromoWheelEntry,
    resetPromoWheelEntryAdded,
  },
  {
    site: null,
    clients: [],
    user: null,
    loading: false,
    fetchClientInQuestionBalanceLoading: false,
    addPromoWheelEntryLoading: false,
    error: null,
    success: null,
    amountToAdd: 0,
    transactionHistory: [],
    userCashOutClassToClear: null,
    clientInQuestion: null,
    clientInQuestionBalance: null,
    cashOutSomeShow: false,
    transactionHistoryUpdatedValues: null,
    promoWheelEntryAdded: false,
  }
)

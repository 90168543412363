import { useContext, useEffect } from 'react'

import { Context as SocketContext } from '../../../context/SocketContext'
import { Context as CashierContext } from '../../../context/CashierContext'

const JackpotResolve = ({ clients }) => {
  const {
    state: { jackpotRelease },
    setSocketDataFromCashier,
  } = useContext(SocketContext)

  const { setJackpotWinnerUsernameDB } = useContext(CashierContext)

  useEffect(() => {
    if (jackpotRelease) {
      if (clients && clients.length > 0) {
        let signedInClients = clients.filter(
          (client) => client.signedIn && client.client
        )
        // Add check length of signedInClients when done testing
        // Add check for only users with credit positive
        let randomIndex = Math.floor(Math.random() * signedInClients.length)
        console.log(`jackpot winner:`, signedInClients[randomIndex])
        setJackpotWinnerUsernameDB({
          jackpotWinnerUsername: signedInClients[randomIndex].username,
        })
        setSocketDataFromCashier({
          subject: 'jackpotWinner',
          jackpotWinnerSelected: true,
          jackpotWinnerUsername: signedInClients[randomIndex].username,
        })
      }
    }
  }, [jackpotRelease, clients])

  return null
}

export default JackpotResolve

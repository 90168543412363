import React, { useContext, useEffect } from 'react'

import Loader from '../../common/loader/Loader'
import Navbar from '../../common/navbar/Navbar'
import CashierModal from '../../common/modals/cashier/CashierModal'
import ClientCell from '../../clientCell/ClientCell'
import JackpotResolve from '../../clientCell/helpers/JackpotResolve'
import { Context as CashierContext } from '../../../context/CashierContext'
// TEST PLAY
import { Context as TestPlayContext } from '../../../context/TestPlayContext'
import './cashier.css'

// TEST PLAY
import TestPlay from '../../common/testPlay/TestPlay'

const Cashier = () => {
  const {
    state: { loading, site, clients },
    fetchClients,
    fetchTransactionHistory,
  } = useContext(CashierContext)

  const {
    state: { success },
  } = useContext(TestPlayContext)

  useEffect(() => {
    if (site) {
      fetchClients({ site })
      fetchTransactionHistory()
    }
  }, [site])

  // TEST PLAY
  useEffect(() => {
    if (
      success === 'cashOutSuccessfull' ||
      success === 'addCreditSuccessfull'
    ) {
      fetchTransactionHistory()
    }
  }, [success])

  const renderListOfClients = () => {
    if (clients.length > 0) {
      return clients.map((client) => {
        return <ClientCell client={client} key={client._id} />
      })
    }
  }

  const renderContent = () => {
    if (loading) return <Loader />
    return (
      <div className="bed">
        <Navbar />
        <div className="cashierUsersListBed">{renderListOfClients()}</div>
      </div>
    )
  }

  return (
    <>
      <TestPlay />
      <JackpotResolve clients={clients} />
      <CashierModal />
      {renderContent()}
    </>
  )
}

export default Cashier

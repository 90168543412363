import { useContext, useEffect } from 'react'

import { Context as CashierContext } from '../../../context/CashierContext'
import { Context as SocketContext } from '../../../context/SocketContext'

const RemoveUserFromCashOutArray = () => {
  const {
    state: { user },
    updateUserCredits,
    clearUser,
  } = useContext(CashierContext)

  const {
    state: { clientCashoutArray, triggerRemoveUserFromCashOutArray },
    setClientCashoutArray,
    setTriggerRemoveUserFromCashOutArray,
  } = useContext(SocketContext)

  useEffect(() => {
    if (user && triggerRemoveUserFromCashOutArray) {
      // updateUserCredits({
      //   creditBalance: user.credits,
      //   id: user._id,
      // })
      removeUserFromCashoutArray()
      setTriggerRemoveUserFromCashOutArray(false)
      clearUser()
    }
  }, [user, clientCashoutArray, triggerRemoveUserFromCashOutArray])

  const removeUserFromCashoutArray = () => {
    let array = clientCashoutArray
    const { username } = user
    const index = array.findIndex(
      (client) => client.clientUsername === username
    )
    if (index !== -1) {
      array.splice(index, 1)
      setClientCashoutArray(array)
    }
  }

  return null
}

export default RemoveUserFromCashOutArray

import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import Loader from '../../loader/Loader'
import { Context as ModalContext } from '../../../../context/ModalContext'
import { Context as CashierContext } from '../../../../context/CashierContext'
import { Context as SocketContext } from '../../../../context/SocketContext'
import './messageModal.css'

const MessageModal = () => {
  const {
    state: { messageModalShow, messageModalProps },
    setMessageModalShow,
    setMessageModalProps,
    setCashierModalProps,
    setCashierModalShow,
  } = useContext(ModalContext)

  const {
    state: { loading, success },
    clearSuccess,
    signOutAllUser,
    signOutOneUser,
  } = useContext(CashierContext)

  const {
    state: { socketTimeoutCheckTrigger, socketTimeout },
    setSocketDataFromCashier,
    setSocketTimeout,
    setSocketTimeoutCheckTrigger,
  } = useContext(SocketContext)

  useEffect(() => {
    if (success) {
      if (success === 'all clients signed out successfully') {
        setSocketDataFromCashier({
          subject: 'siteSignedOutSuccessfully',
          siteSignedOutSuccessfully: true,
        })
      }
    }
  }, [success])

  useEffect(() => {
    if (success) {
      if (success === 'client signed out successfully') {
        const { clientUsername } = messageModalProps
        setSocketDataFromCashier({
          subject: 'cashierSignedClientOut',
          cashierSignedClientOut: true,
          clientUsername: clientUsername,
        })
      }
    }
  }, [success, messageModalProps])

  useEffect(() => {
    if (socketTimeoutCheckTrigger) {
      setMessageModalProps({
        bit: 'waitingForSocketConfirm',
      })
      const run = setTimeout(() => {
        setSocketTimeout(true)
      }, 5000)
      return () => {
        clearTimeout(run)
      }
    }
  }, [socketTimeoutCheckTrigger])

  useEffect(() => {
    if (socketTimeout) {
      setMessageModalShow(true)
      setMessageModalProps({
        bit: 'socketTimeout',
      })
    }
  }, [socketTimeout])

  const contentWaitingForSocketConfirm = () => {
    return <div className="messageModalText">waiting for socket</div>
  }

  const contentSignOutAllUsers = () => {
    const { clients } = messageModalProps
    if (!success) {
      return (
        <>
          <div className="messageModalText">
            Sign out all clients, <br />
            are you sure?
          </div>
          <div className="messageModalButtonBed">
            <div
              className="messageModalButton"
              onClick={() => signOutAllUser({ clients })}
            >
              yes
            </div>
            <div
              className="messageModalButton"
              onClick={() => {
                clearSuccess()
                setMessageModalShow(false)
                setMessageModalProps(null)
              }}
            >
              no
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        <div className="messageModalText">{success}</div>
        <div className="messageModalButtonBed">
          <div
            className="messageModalButton"
            onClick={() => {
              clearSuccess()
              setMessageModalShow(false)
              setMessageModalProps(null)
            }}
          >
            OK
          </div>
        </div>
      </>
    )
  }

  const contentSignOutOneUser = () => {
    const { clientUsername } = messageModalProps
    if (!success) {
      return (
        <>
          <div className="messageModalText">
            Sign out "{clientUsername}"", <br />
            are you sure?
          </div>
          <div className="messageModalButtonBed">
            <div
              className="messageModalButton"
              onClick={() => signOutOneUser({ clientUsername })}
            >
              yes
            </div>
            <div
              className="messageModalButton"
              onClick={() => {
                clearSuccess()
                setMessageModalShow(false)
                setMessageModalProps(null)
              }}
            >
              no
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        <div className="messageModalText">{success}</div>
        <div className="messageModalButtonBed">
          <div
            className="messageModalButton"
            onClick={() => {
              clearSuccess()
              setMessageModalShow(false)
              setMessageModalProps(null)
            }}
          >
            OK
          </div>
        </div>
      </>
    )
  }

  const contentSocketTimeout = () => {
    return (
      <>
        <div className="messageModalText">Connection with client failed</div>
        <div className="messageModalText">
          Make sure the client's in full screen mode.
        </div>
        <div className="messageModalText">
          If the error persist, please refresh both client's and cashier's
          browsers.
        </div>
        <div className="messageModalButtonBed">
          <div
            className="messageModalButton"
            onClick={() => {
              setSocketTimeoutCheckTrigger(false)
              setSocketTimeout(false)
              setCashierModalProps(null)
              setCashierModalShow(false)
              setMessageModalProps(null)
              setMessageModalShow(false)
            }}
          >
            OK
          </div>
        </div>
      </>
    )
  }

  const contentSelector = () => {
    const { bit } = messageModalProps
    switch (bit) {
      case 'waitingForSocketConfirm':
        return contentWaitingForSocketConfirm()
      case 'signOutAllUser':
        return contentSignOutAllUsers()
      case 'signOutOneUser':
        return contentSignOutOneUser()
      case 'socketTimeout':
        return contentSocketTimeout()
      default:
        break
    }
  }

  if (loading) return <Loader />

  if (!messageModalShow) return null

  const renderContent = () => {
    return (
      <div className="messageModalBed">
        <div className="messageModalContainer">{contentSelector()}</div>
      </div>
    )
  }

  return ReactDOM.createPortal(
    <div className="ui dimmer active">{renderContent()}</div>,
    document.querySelector('#modal')
  )
}

export default MessageModal

import React, { useState, useContext, useEffect } from 'react'

import { Context as CashierContext } from '../../../../../../context/CashierContext'
import { Context as SocketContext } from '../../../../../../context/SocketContext'
import './cashOutSome.css'

const CashOutSome = () => {
  const [cashOutValue, setCashOutValue] = useState(0)
  const [cashOutValueInvalid, setCashOutValueInvalid] = useState(false)

  const {
    state: { user },
    setCashOutSomeShow,
    updateUserCredits,
    setTransactionHistoryUpdatedValues,
  } = useContext(CashierContext)

  const { setSocketTimeoutCheckTrigger, setSocketTimeout } =
    useContext(SocketContext)

  useEffect(() => {
    if (user.credits < cashOutValue) {
      setCashOutValueInvalid(true)
    }
    if (user.credits > cashOutValue) {
      setCashOutValueInvalid(false)
    }
  }, [user, cashOutValue])

  const handleNumberClick = (number) => {
    setCashOutValue((prevValue) => prevValue * 10 + number)
  }

  const clearCashOutValue = () => {
    setCashOutValue(cashOutValue - cashOutValue)
  }

  const renderNewCeditBalance = () => {
    let cashOutValueSubtraced = user.credits - cashOutValue
    let cashOutValueFormatted = (cashOutValueSubtraced / 100).toFixed(2)
    if (cashOutValue > user.credits) {
      return <div className="cashOutCalculatorDataText">Invalid amount</div>
    }
    return (
      <>
        <div className="cashOutCalculatorDataTextLabel">New balance</div>
        <div className="cashOutCalculatorDataText">{cashOutValueFormatted}</div>
      </>
    )
  }

  const renderContent = () => {
    return (
      <div className="cashOutCalculatorBed">
        <div className="cashOutCalculatorContainer">
          <div className="cashOutCalculatorTop">
            <div
              className="cashOutCalculatorConfirmButton"
              onClick={
                cashOutValueInvalid
                  ? null
                  : () => {
                      setTransactionHistoryUpdatedValues({
                        clientNewBalance: user.credits - cashOutValue,
                        transactionValue: cashOutValue,
                      })
                      updateUserCredits({
                        creditBalance: user.credits - cashOutValue,
                        id: user._id,
                      })
                      setCashOutSomeShow(false)
                      setSocketTimeoutCheckTrigger(false)
                      setSocketTimeout(false)
                    }
              }
            >
              Confirm
            </div>
            <div className="cashOutCalculatorDataRowsBed">
              <div className="cashOutCalculatorDataRow">
                <div className="cashOutCalculatorDataText">
                  {user.username.slice(6)}
                </div>
              </div>
              <div className="cashOutCalculatorDataRow">
                <div className="cashOutCalculatorDataTextLabel">Credits</div>
                <div className="cashOutCalculatorDataText">
                  {(user.credits / 100).toFixed(2)}
                </div>
              </div>
              <div className="cashOutCalculatorDataRow">
                <div className="cashOutCalculatorDataTextLabel">
                  Cash out amount
                </div>
                <div className="cashOutCalculatorDataText">
                  {(cashOutValue / 100).toFixed(2)}
                </div>
              </div>
              <div className="cashOutCalculatorDataRow">
                {renderNewCeditBalance()}
              </div>
            </div>
          </div>
          <div className="cashOutCalculatorBottom">
            <div className="cashOutCalculatorButtonRow">
              <div
                className="cashOutCalculatorButton"
                onClick={() => handleNumberClick(7)}
              >
                7
              </div>
              <div
                className="cashOutCalculatorButton"
                onClick={() => handleNumberClick(8)}
              >
                8
              </div>
              <div
                className="cashOutCalculatorButton"
                onClick={() => handleNumberClick(9)}
              >
                9
              </div>
            </div>
            <div className="cashOutCalculatorButtonRow">
              <div
                className="cashOutCalculatorButton"
                onClick={() => handleNumberClick(4)}
              >
                4
              </div>
              <div
                className="cashOutCalculatorButton"
                onClick={() => handleNumberClick(5)}
              >
                5
              </div>
              <div
                className="cashOutCalculatorButton"
                onClick={() => handleNumberClick(6)}
              >
                6
              </div>
            </div>
            <div className="cashOutCalculatorButtonRow">
              <div
                className="cashOutCalculatorButton"
                onClick={() => handleNumberClick(1)}
              >
                1
              </div>
              <div
                className="cashOutCalculatorButton"
                onClick={() => handleNumberClick(2)}
              >
                2
              </div>
              <div
                className="cashOutCalculatorButton"
                onClick={() => handleNumberClick(3)}
              >
                3
              </div>
            </div>
            <div className="cashOutCalculatorButtonRow">
              <div
                className="cashOutCalculatorButton"
                onClick={() => clearCashOutValue()}
              >
                Clear
              </div>
              <div
                className="cashOutCalculatorButton"
                onClick={() => handleNumberClick(0)}
              >
                0
              </div>
              <div
                className="cashOutCalculatorButton"
                onClick={() => {
                  clearCashOutValue()
                  setCashOutSomeShow(false)
                  setSocketTimeoutCheckTrigger(false)
                  setSocketTimeout(false)
                }}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return renderContent()
}

export default CashOutSome

import createDataContext from './createDataContext'

// Reducer
const CashLogsContext = (state, action) => {
  switch (action.type) {
    case 'SET_START_DATE':
      return { ...state, startDate: action.payload }
    case 'SET_END_DATE':
      return { ...state, endDate: action.payload }
    case 'SET_REQUESTED_LOGS':
      return { ...state, requestedLogs: action.payload }
    case 'SET_MONEY_IN':
      return { ...state, moneyIn: action.payload }
    case 'SET_MONEY_OUT':
      return { ...state, moneyOut: action.payload }
    default:
      return state
  }
}

// Actions
const setStartDate = (dispatch) => (date) => {
  dispatch({ type: 'SET_START_DATE', payload: date })
}

const setEndDate = (dispatch) => (date) => {
  dispatch({ type: 'SET_END_DATE', payload: date })
}

const setRequestedLogs = (dispatch) => (date) => {
  dispatch({ type: 'SET_REQUESTED_LOGS', payload: date })
}

const setMoneyIn = (dispatch) => (value) => {
  dispatch({ type: 'SET_MONEY_IN', payload: value })
}

const setMoneyOut = (dispatch) => (value) => {
  dispatch({ type: 'SET_MONEY_OUT', payload: value })
}

export const { Context, Provider } = createDataContext(
  CashLogsContext,
  {
    setStartDate,
    setEndDate,
    setRequestedLogs,
    setMoneyIn,
    setMoneyOut,
  },
  // Initial state
  {
    startDate: new Date(),
    endDate: new Date(),
    requestedLogs: [],
    moneyIn: 0,
    moneyOut: 0,
  }
)

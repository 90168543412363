import { useContext, useEffect } from 'react'
import moment from 'moment'

import { Context as CashierContext } from '../../../context/CashierContext'
import { Context as CashLogsContext } from '../../../context/CashLogsContext'

const CalculateDailyCash = () => {
  const {
    state: { transactionHistory },
    fetchTransactionHistory,
  } = useContext(CashierContext)

  const {
    state: { startDate, endDate, requestedLogs, moneyIn, moneyOut },
    setRequestedLogs,
    setMoneyIn,
    setMoneyOut,
  } = useContext(CashLogsContext)

  useEffect(() => {
    fetchTransactionHistory()
  }, [])

  useEffect(() => {
    if (transactionHistory.length > 0) {
      if (moment(startDate).format() === moment(endDate).format()) {
        let sameDayArray = []
        sameDayArray = transactionHistory.filter((trans) => {
          return (
            moment(trans.created).format('L') === moment(startDate).format('L')
          )
        })
        setRequestedLogs(sameDayArray)
      } else {
        let filteredTransactions = []
        filteredTransactions = transactionHistory.filter((trans) => {
          return moment(trans.created).isBetween(startDate, endDate)
        })
        setRequestedLogs(filteredTransactions)
      }
    }
  }, [transactionHistory, startDate, endDate])

  useEffect(() => {
    if (requestedLogs.length > 0) {
      let typeAddCredit = []
      let typeCashOut = []
      typeAddCredit = requestedLogs.filter((reqLog) => {
        return reqLog.type === 'addCredit'
      })
      typeCashOut = requestedLogs.filter((reqLog) => {
        return reqLog.type === 'cashOut'
      })
      if (typeAddCredit.length > 0) {
        let valuesIn = []
        let sumIn = 0
        valuesIn = typeAddCredit.map((addCred) => {
          return addCred.value
        })
        valuesIn.forEach((value) => {
          sumIn += value
        })
        setMoneyIn(sumIn)
      }
      if (typeCashOut.length > 0) {
        let valuesOut = []
        let sumOut = 0
        valuesOut = typeCashOut.map((cashOut) => {
          return cashOut.value
        })
        valuesOut.forEach((value) => {
          sumOut += value
        })
        setMoneyOut(sumOut)
      }
    }
  }, [requestedLogs])

  return null
}

export default CalculateDailyCash

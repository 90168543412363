import React, { useState, useContext, useEffect } from 'react'
import { RiComputerLine } from 'react-icons/ri'
import { AiFillCheckCircle } from 'react-icons/ai'

import { Context as CashierContext } from '../../../../../context/CashierContext'
import { Context as ModalContext } from '../../../../../context/ModalContext'
import { Context as SocketContext } from '../../../../../context/SocketContext'
import './addCredit.css'

const AddCredit = () => {
  const [trigger, setTrigger] = useState(false)

  const { setCashierModalShow } = useContext(ModalContext)

  const {
    state: {
      user,
      amountToAdd,
      site,
      error,
      success,
      promoWheelEntryAdded,
      addPromoWheelEntryLoading,
    },
    clearUser,
    clearSuccess,
    setAmountToAdd,
    updateUserCredits,
    recordTransactionHistory,
    addPromoWheelEntry,
  } = useContext(CashierContext)

  const {
    setSocketDataFromCashier,
    setSocketTimeoutCheckTrigger,
    setSocketTimeout,
  } = useContext(SocketContext)

  useEffect(() => {
    if (success === 'users credit updated') {
      addPromoWheelEntry({ clientId: user._id, promoWheelEntries: 1 })
    }
  }, [success])

  useEffect(() => {
    if (promoWheelEntryAdded) {
      setTrigger(true)
    }
  }, [promoWheelEntryAdded])

  useEffect(() => {
    if (trigger) {
      recordTransactionHistory({
        siteNumber: site,
        clientId: user._id,
        clientUsername: user.username,
        type: 'addCredit',
        value: amountToAdd,
        clientOldBalance: user.credits,
        clientNewBalance: user.credits + amountToAdd,
      })
      setAmountToAdd(0)
      setCashierModalShow(false)
      setSocketDataFromCashier({
        subject: 'addCreditDone',
        clientUsername: user.username,
        addCreditDone: true,
        gameCredit: user.credits + amountToAdd,
      })
      clearSuccess()
      clearUser()
    }
  }, [error, trigger])

  const renderContent = () => {
    if (!user) return null
    if (error) {
      return (
        <div className="transationErrorBed">
          <div className="transationErrorText">{error}</div>
        </div>
      )
    }
    let letUserCreditsFormatted = (user.credits / 100).toFixed(2)
    let amountToAddFormatted = (amountToAdd / 100).toFixed(2)
    let total = user.credits + amountToAdd
    let totalFormatted = (total / 100).toFixed(2)
    return (
      <>
        <div className="typeAddCreditTop">
          <div className="typeAddCreditTopLeft">
            <div className="typeAddCreditLabel">{user.username.slice(6)}</div>
            <RiComputerLine className="typeAddCreditPcIcon" />
          </div>
          <div className="typeAddCreditTopRight">
            <div className="typeAddCreditTopRightContainer">
              <div className="typeAddCreditTopRightLeft">
                <div className="typeAddCreditTopRightLeft">
                  <div className="typeAddCreditCreditText">
                    Current balance:
                  </div>
                  {amountToAdd === 0 ? null : (
                    <>
                      <div className="typeAddCreditCreditText">
                        Amount adding:
                      </div>
                      <div className="typeAddCreditCreditText">
                        New balance:
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="typeAddCreditTopRightRight">
                <div className="typeAddCreditCreditText">
                  {letUserCreditsFormatted}
                </div>
                {amountToAdd === 0 ? null : (
                  <>
                    <div className="typeAddCreditCreditText">
                      {amountToAddFormatted}
                    </div>
                    <div className="typeAddCreditCreditText">
                      {totalFormatted}
                    </div>
                  </>
                )}
              </div>
            </div>
            {amountToAdd === 0 ? null : (
              <div className="typeAddCreditSubmitBed">
                <AiFillCheckCircle
                  className="typeAddCreditSubmitConfirm"
                  onClick={() => {
                    updateUserCredits({
                      creditBalance: user.credits + amountToAdd,
                      id: user._id,
                    })
                    setSocketTimeoutCheckTrigger(false)
                    setSocketTimeout(false)
                  }}
                />
                <div className="typeAddCreditClearButtonBed">
                  <div
                    className="typeAddCreditClearButton"
                    onClick={() => setAmountToAdd(0)}
                  >
                    clear
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="typeAddCreditBottom">
          <div className="typeAddCreditBottomContainer">
            <div
              className="typeAddCreditValue10"
              onClick={() => setAmountToAdd(amountToAdd + 1000)}
            >
              <div className="typeAddCreditValueTextBed">
                <div className="typeAddCreditValueText">10</div>
              </div>
            </div>
            <div
              className="typeAddCreditValue20"
              onClick={() => setAmountToAdd(amountToAdd + 2000)}
            >
              <div className="typeAddCreditValueTextBed">
                <div className="typeAddCreditValueText">20</div>
              </div>
            </div>
            <div
              className="typeAddCreditValue50"
              onClick={() => setAmountToAdd(amountToAdd + 5000)}
            >
              <div className="typeAddCreditValueTextBed">
                <div className="typeAddCreditValueText">50</div>
              </div>
            </div>
            <div
              className="typeAddCreditValue100"
              onClick={() => setAmountToAdd(amountToAdd + 10000)}
            >
              <div className="typeAddCreditValueTextBed">
                <div className="typeAddCreditValueText">100</div>
              </div>
            </div>
            <div
              className="typeAddCreditValue200"
              onClick={() => setAmountToAdd(amountToAdd + 20000)}
            >
              <div className="typeAddCreditValueTextBed">
                <div className="typeAddCreditValueText">200</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return addPromoWheelEntryLoading ? null : renderContent()
}

export default AddCredit
